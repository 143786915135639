import React from 'react'
import Layout from 'components/Layout/Layout'
import styled from 'styled-components'
import SEO from 'components/Seo'
import { ThankYouProps } from 'components/Props/thankyou'
import { graphql } from 'gatsby'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import { md, sm, lg } from 'config/variables'

export const ThankYouPageWrap = styled.div`
  .buildapp-section {
    padding-top: 150px;
    margin-bottom: 110px;
    ${lg(`
      padding-top: 110px;
      margin-bottom: 90px;
    `)}
    ${md(`
      padding-top: 110px;
      margin-bottom: 90px;
    `)}
    ${sm(`
      padding-top: 80px;
      margin-bottom: 50px;
    `)}
    &:before {
      display: none;
    }
  }
`

const ThankYouPage = props => {
  const { location } = props
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  return (
    <Layout mainClass="not-found-page" location={location}>
      <SEO
        title="Thank you"
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
        ]}
      />
      <ThankYouPageWrap>
        <BuildApplication
          buildApplicationData={ThankYouProps}
          posterImage={posterImage}
        />
        <FeatureResources featureresourceData={ThankYouProps} {...props} />
      </ThankYouPageWrap>
    </Layout>
  )
}

export default ThankYouPage

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/404-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 547) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-outsourcing-lessons@2x/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-build-scalable@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-ecommerce-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
