import React from 'react'
import { images } from 'config/images'

export const ThankYouProps = {
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">Thanks</span> for reaching out to us!
      </>
    ),
    buildAppPara: (
      <>
        <span className="color-through">
          Here's a personal message for you.
        </span>
      </>
    ),
    buildVid: 'faTxnc17Rs0',
    autoPlay: 'autoPlay',
    poster: images.thankYouImage,
    lightboxImgVid: 'https://player.vimeo.com/video/348319194',
    videoNote: 'Play video to see how we work',
  },
  featureResourceProps: {
    heading: 'Check out some interesting insights',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/top-outsourcing-lessons-learned-from-5-successful-and-failed-startups/',
        featurecardTitle:
          '9 Outsourcing Lessons Learned from Successful and Failed startups',
      },
      {
        blogLink:
          'https://www.simform.com/building-scalable-application-aws-platform/',
        featurecardTitle:
          'How to Build a Scalable Application up to 1 Million Users on AWS',
      },
      {
        blogLink: 'https://www.simform.com/how-to-develop-ecommerce-apps/',
        featurecardTitle: 'How to Make an eCommerce App ',
      },
    ],
  },
}
